import React, { useEffect, useState } from "react";

import { MainNav, SecondaryNav } from "../../index";

import "./mobile-menu.scss";

const MobileMenu = () => {
  const [winHeight, setWinHeight] = useState(undefined);
  const handleMobileResize = () => {
    setWinHeight(window.innerHeight);
  };

  useEffect(() => {
    if (window) {
      window.addEventListener("resize", handleMobileResize());
    }

    return () => {
      if (window) {
        window.removeEventListener("resize", handleMobileResize());
      }
    };
  }, []);

  return (
    <div
      className="mobile-menu"
      style={
        winHeight
          ? {
              height: winHeight,
            }
          : {}
      }
    >
      <MainNav />
      <SecondaryNav />
    </div>
  );
};

export default MobileMenu;
